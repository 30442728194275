import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyD-r4g303pe9k0KTh3rAF2k1uMglbZjlcE",
    authDomain: "energywebnfts.firebaseapp.com",
    databaseURL: "https://energywebtransactions.firebaseio.com",
    projectId: "energywebnfts",
    storageBucket: "energywebnfts.appspot.com",
    messagingSenderId: "355811404964",
    appId: "1:355811404964:web:52c869d4269fed54080a31",
    measurementId: "G-5PX3L6B7CN"
};

const app_two = initializeApp({
    apiKey: "AIzaSyANuJiG-HTX_l5YZ9CePtZI1OdtebvVkhQ",
    authDomain: "tubbyturtles-86bc9.firebaseapp.com",
    databaseURL: "https://tubbyturtles-86bc9-default-rtdb.firebaseio.com",
    projectId: "tubbyturtles-86bc9",
    storageBucket: "tubbyturtles-86bc9.appspot.com",
    messagingSenderId: "817724247427",
    appId: "1:817724247427:web:a13de579e822a539307ab6",
    measurementId: "G-ZYRP703W22"
}, 'appTwo');

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
export const dbTwo = getDatabase(app_two)

