import ewcnftlogo from '../assets/images/ewcnftlogo.png';
import { AiOutlineLink } from "react-icons/ai";
import Toggle from './Toggle';
import { useEffect, useState } from 'react';
import { ethers } from "ethers";
import { dbTwo } from '../firebase';
import { onValue, ref } from 'firebase/database';
import ReactTooltip from 'react-tooltip'

import tttlogoUnknown from '../assets/images/tttlogoUnknown.png'
import tttlogoYes from '../assets/images/tttlogoYes.png'
import tttlogoNo from '../assets/images/tttlogoNo.png'

const Header = () => {
    const [userAccounts, setUserAccounts] = useState([])
    const [holdersList, setHoldersList] = useState([])
    const [hasTurtle, setHasTurtle] = useState(false)

    async function connect() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let accounts = await provider.send("eth_requestAccounts", []);
        setUserAccounts(accounts);
        console.log(JSON.parse(localStorage.getItem('hasTurtle')))
    }

    useEffect(() => {
        try {
        for (let index = 0; index < holdersList.length; index++) {
            const element = holdersList[index]["address"];
            for (let index = 0; index < userAccounts.length; index++) {
                const adressToCheck = userAccounts[index];
                if (element === adressToCheck) {
                    setHasTurtle(true);
                    localStorage.setItem('hasTurtle', 'true');
                }
            }
        }
        if (userAccounts.length > 0 && hasTurtle === false) {
            document.getElementById("hasTurtleIcon").src = tttlogoNo
            //localStorage.setItem('hasTurtle', 'false');
        }
        if (JSON.parse(localStorage.getItem('hasTurtle')) === true) { setHasTurtle(true); }
        } catch {console.log('error')} 
    }, [holdersList, userAccounts])

    useEffect(() => {
        onValue(ref(dbTwo), snapshot => {
            const data = snapshot.val();
            setHoldersList(data["turtleHolders"])
        });
    }, []);


    return (
        <header className='z-5 w-full h-[56px] lg:h-[60px] bg-bgprimary dark:bg-darkbgprimary transition-all flex items-center justify-between' >
            <div className='shadow-[0_0px_10px_3px_rgba(15,23,35,0.30)] dark:shadow-[0_0px_10px_3px_rgba(245,245,230,0.2)] w-full h-full
            bg-gradient-to-r from-[#9AEFA2] via-[#8DD7E4] to-[#C8A1FB] 
            dark:bg-gradient-to-r dark:from-[#368B3E] dark:via-[#297380] dark:to-[#643D97]
            transition-all flex flex-row'>
                <a href='https://energywebnfts.com/' className='flex flex-row ml-4 sm:ml-6 md:ml-10'>
                    <img className='rounded-lg 2xs:w-[34px!important] 2xs:h-[34px!important] mr-1 h-10 my-auto filter brightness-[90%] dark:brightness-[110%]' src={ewcnftlogo} alt="EnergyWebNFTs Logo" />
                    <h1 className='xsm:text-xl xs:text-lg 2xs:text-[1.05rem] flex flex-row my-auto font-bold text-2xl ml-1 mr-2 text-textprimary dark:text-darktextprimary transition-all'>
                        EnergyWebNFTs
                        <AiOutlineLink className='-translate-y-[5px] translate-x-[3px]' />
                    </h1>
                </a>
                <div className='h-full ml-auto mr-4 sm:mr-6 md:mr-10 flex flex-row-reverse items-center'>
                    <Toggle/>
                    {hasTurtle === false ? (
                        <>
                            <img data-for='custom-color' data-tip="Extra features for Tubby Turtle holders, click to connect" onClick={connect} id='hasTurtleIcon' className='hover:cursor-pointer hover:brightness-[1.15] h-[75%] xs:mr-1 mr-2 sm:mr-3 md:mr-4 -translate-y-[3px]' src={tttlogoUnknown} alt="" />
                            <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                        </>
                    ) : (
                        <>
                            <img data-for='custom-color' data-tip="You are holding a Tubby Turtle so you have unlocked some extra transaction tracking features" className='h-[75%] xs:mr-1 mr-2 sm:mr-3 md:mr-4 -translate-y-[3px]' src={tttlogoYes} alt="" />
                            <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                        </>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;