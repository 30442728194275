import { useEffect, useState, useRef } from 'react';
import EWTlogo from './assets/images/EWTlogo.png';
import { db } from './firebase';
import { onValue, ref } from 'firebase/database';
import newTxAudio from './assets/audio/newEwtTxNotification.mp3';
import { BsArrowRight } from 'react-icons/bs';
import { AiFillBell } from 'react-icons/ai';
import electrobuzz from './assets/images/electrobuzz.png';
import jolteon from './assets/images/jolteon.png';
import zapdos from './assets/images/zapdos.png';
import pichu from './assets/images/pichu.png';
import pickachu from './assets/images/pickachu.png';
import raichu from './assets/images/raichu.png';
import useColorChange from 'use-color-change';
import MoonLoader from 'react-spinners/MoonLoader';
import ReactTooltip from 'react-tooltip';
import { AiOutlineLink } from "react-icons/ai";
import turtlecollapse from './assets/images/collapse.png'
import turtleuncollapse from './assets/images/uncollapse.png'

import stakingpoollogo from './assets/images/stakingpoollogo.png';
import kucoinlogo from './assets/images/kucoinlogo.png';
import krakenlogo from './assets/images/krakenlogo.png';
import gateiologo from './assets/images/gateiologo.png';
import hotbitlogo from './assets/images/hotbitlogo.png';
import ewflogo from './assets/images/ewflogo.png';
import ewchainiologo from './assets/images/ewchainiologo.png';
import liquidlogo from './assets/images/liquidlogo.png';
import bitmartlogo from './assets/images/bitmartlogo.png';

const App = () => {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  function useCompare(val) {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  const [dataList, setDatalist] = useState([])
  const [txlist, setTxlist] = useState([])
  const [txlistlength, setTxlistlength] = useState(0)
  const [currentblock, setCurrentblock] = useState(0)

  const hasItemIdChanged = useCompare(txlistlength);

  useEffect(() => {
    onValue(ref(db), snapshot => {
      const data = snapshot.val();
      setDatalist(data)
    });
  }, []);

  function secondsToTime(e) {
    const h = Math.floor(e / 3600).toString().padStart(2, '0'),
      m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
      s = Math.floor(e % 60).toString().padStart(2, '0');

    return h + ':' + m + ':' + s;
    //return `${h}:${m}:${s}`;
  }

  function secondsToTime2(e) {
    const d = Math.floor(e / 86400).toString().padStart(2, '0'),
      h = Math.floor(e % 86400 / 3600).toString().padStart(2, '0'),
      m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
      s = Math.floor(e % 60).toString().padStart(2, '0');

    return d + ':' + h + ':' + m + ':' + s;
    //return `${h}:${m}:${s}`;
  }

  const [inKucoin, setInKucoin] = useState(0)
  const [inKraken, setInKraken] = useState(0)
  const [inStaking, setInStaking] = useState(0)
  const [inGateio, setInGateio] = useState(0)
  const [inHotbit, setInHotbit] = useState(0)
  const [inLiquid, setInLiquid] = useState(0)
  const [inBitmart, setInBitmart] = useState(0)
  const [inTotalExchanges, setInTotalExchanges] = useState(0)

  const [outKucoin, setOutKucoin] = useState(0)
  const [outKraken, setOutKraken] = useState(0)
  const [outStaking, setOutStaking] = useState(0)
  const [outGateio, setOutGateio] = useState(0)
  const [outHotbit, setOutHotbit] = useState(0)
  const [outLiquid, setOutLiquid] = useState(0)
  const [outBitmart, setOutBitmart] = useState(0)
  const [outTotalExchanges, setOutTotalExchanges] = useState(0)

  const [time100Tx, setTime100Tx] = useState(0)
  const [stakingPercentage, setStakingPercentage] = useState(0)
  useEffect(() => {
    if (dataList !== [] && dataList.length !== 0) {
      let templist = [];
      for (var key in dataList["transactionData"]) {
        if (dataList["transactionData"].hasOwnProperty(key)) {
          templist.push([dataList["transactionData"][key]]);
        }
      }
      setCurrentblock(dataList["blockNr"])
      setTxlist(templist.reverse())
      setTxlistlength(templist.length)

      setInKucoin(dataList["inKucoin"])
      setInKraken(dataList["inKraken"])
      setInStaking(dataList["inStaking"])
      setInGateio(dataList["inGateio"])
      setInHotbit(dataList["inHotbit"])
      setInLiquid(dataList["inLiquid"])
      setInBitmart(dataList["inBitmart"])
      setInTotalExchanges(dataList["inTotalExchanges"])

      setOutKucoin(dataList["outKucoin"])
      setOutKraken(dataList["outKraken"])
      setOutStaking(dataList["outStaking"])
      setOutGateio(dataList["outGateio"])
      setOutHotbit(dataList["outHotbit"])
      setOutLiquid(dataList["outLiquid"])
      setOutBitmart(dataList["outBitmart"])
      setOutTotalExchanges(dataList["outTotalExchanges"])

      setStakingPercentage(dataList["stakingPoolPercentage"])

      if (txlist.length > 99 && currentblock > 0) {
        setTime100Tx(secondsToTime((currentblock - (txlist[99][0]["blockHash"])) * 5))
      }
    }
  }, [dataList])

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function playAudio() {
    try {
      document.getElementById("myAudio").play();
      await sleep(1200)
      document.getElementById("myAudio").stop();
    } catch {console.log('error')}
  }

  useEffect(() => {
    playAudio()
  }, [hasItemIdChanged])

  const colorStyle = useColorChange(currentblock, {
    higher: 'rgba(35, 136, 35, 10)',
    lower: 'rgba(210, 34, 45, 10)',
    duration: 500
  });

  async function notifsOn() {
    document.getElementById('notifs').innerText = "Pressed!👍"
  }

  let hasTurtle;
  if (JSON.parse(localStorage.getItem('hasTurtle')) === true) {
    hasTurtle = true;
  }

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const [currentBlock, setCurrentBlock] = useState(0)
  const [oldestBlock, setOldestBlock] = useState(0)
  const [txList, setTxList] = useState([])

  useEffect(() => {
    onValue(ref(db), snapshot => {
        const data = snapshot.val();
        setOldestBlock((Object.values(data["transactionData"]))[0]);
        setCurrentBlock(data["blockNr"]);
        setTxList(data["transactionData"]);
    });
  }, []);

  const [customStakingIn, setCustomStakingIn] = useState(0); const [customStakingOut, setCustomStakingOut] = useState(0);
  const [customKucoinIn, setCustomKucoinIn] = useState(0); const [customKucoinOut, setCustomKucoinOut] = useState(0);
  const [customKrakenIn, setCustomKrakenIn] = useState(0); const [customKrakenOut, setCustomKrakenOut] = useState(0);
  const [customGateioIn, setCustomGateioIn] = useState(0); const [customGateioOut, setCustomGateioOut] = useState(0);
  const [customHotbitIn, setCustomHotbitIn] = useState(0); const [customHotbitOut, setCustomHotbitOut] = useState(0);
  const [customLiquidIn, setCustomLiquidIn] = useState(0); const [customLiquidOut, setCustomLiquidOut] = useState(0);
  const [customBitmartIn, setCustomBitmartIn] = useState(0); const [customBitmartOut, setCustomBitmartOut] = useState(0);
  const [customTotalIn, setCustomTotalIn] = useState(0); const [customTotalOut, setCustomTotalOut] = useState(0);

  const [queryBlocks, setQueryBlocks] = useState(0)
  async function getInflowOutflowDataByTime(amountBlocksAgo) {
    setQueryBlocks(amountBlocksAgo)
    setCustomStakingOut(0);setCustomStakingIn(0);
    setCustomKucoinOut(0);setCustomKucoinIn(0);
    setCustomKrakenOut(0);setCustomKrakenIn(0);
    setCustomGateioOut(0);setCustomGateioIn(0);
    setCustomHotbitOut(0);setCustomHotbitIn(0);
    setCustomLiquidOut(0);setCustomLiquidIn(0);
    setCustomBitmartOut(0);setCustomBitmartIn(0);
    setCustomTotalOut(0);setCustomTotalIn(0);
    let letcustomStakingOut = 0; let letcustomStakingIn = 0;
    let letcustomKucoinOut = 0; let letcustomKucoinIn = 0;
    let letcustomKrakenOut = 0; let letcustomKrakenIn = 0;
    let letcustomGateioOut = 0; let letcustomGateioIn = 0;
    let letcustomHotbitOut = 0; let letcustomHotbitIn = 0;
    let letcustomLiquidOut = 0; let letcustomLiquidIn = 0;
    let letcustomBitmartOut = 0; let letcustomBitmartIn = 0;
    let letcustomTotalOut = 0; let letcustomTotalIn = 0;
    let hasTurtleTxList = []
    if (Object.values(txList) !== []) {
        for (let index = 0; index < Object.values(txList).length; index++) {
            const element = Object.values(txList)[index];
            if (parseInt(element["blockHash"]) > currentBlock-amountBlocksAgo) {
                hasTurtleTxList.push(element)
            }
        }
    }
    for (let index = 0; index < hasTurtleTxList.length; index++) {
      const element = hasTurtleTxList[index];
      if (element["from"] === "0xA507B00463ce82DF6a105Ab05f810809fAedd538") { letcustomStakingOut = letcustomStakingOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0xA507B00463ce82DF6a105Ab05f810809fAedd538") { letcustomStakingIn = letcustomStakingIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0xB0a384fAe14fa7A66Cc7Dd52e079ca2264A05b00") { letcustomKucoinOut = letcustomKucoinOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0xB0a384fAe14fa7A66Cc7Dd52e079ca2264A05b00") { letcustomKucoinIn = letcustomKucoinIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0x89e3Ab767cd56E69A18Cde04C81ABc6520741A62") { letcustomKucoinOut = letcustomKucoinOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0x89e3Ab767cd56E69A18Cde04C81ABc6520741A62") { letcustomKucoinIn = letcustomKucoinIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0x7136255E5758397506C360625961cc0de6956027") { letcustomKrakenOut = letcustomKrakenOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0x0c074090e8216f0600585AADF586F3DE84B2Ee83") { letcustomKrakenIn = letcustomKrakenIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0x0D0707963952f2fBA59dD06f2b425ace40b492Fe") { letcustomGateioOut = letcustomGateioOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0x0D0707963952f2fBA59dD06f2b425ace40b492Fe") { letcustomGateioIn = letcustomGateioIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0xA3C8Ae0772Cf6AF0295131B2E83C79ba3C5A6e58") { letcustomHotbitOut = letcustomHotbitOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0xA3C8Ae0772Cf6AF0295131B2E83C79ba3C5A6e58") { letcustomHotbitIn = letcustomHotbitIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0x07EA3141809F21e19D2d41c8EC5A1244C247FA0f") { letcustomLiquidOut = letcustomLiquidOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0x07EA3141809F21e19D2d41c8EC5A1244C247FA0f") { letcustomLiquidIn = letcustomLiquidIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }

      if (element["from"] === "0xC2898A2dF40969F45aC8681155FC354057Ac5f76") { letcustomBitmartOut = letcustomBitmartOut+parseInt(element["txvalue"]); letcustomTotalOut = letcustomTotalOut+parseInt(element["txvalue"]); }
      if (element["to"] === "0xC2898A2dF40969F45aC8681155FC354057Ac5f76") { letcustomBitmartIn = letcustomBitmartIn+parseInt(element["txvalue"]); letcustomTotalIn = letcustomTotalIn+parseInt(element["txvalue"]); }
    }
    setCustomStakingOut(letcustomStakingOut); setCustomStakingIn(letcustomStakingIn); 
    setCustomKucoinOut(letcustomKucoinOut); setCustomKucoinIn(letcustomKucoinIn); 
    setCustomKrakenOut(letcustomKrakenOut); setCustomKrakenIn(letcustomKrakenIn); 
    setCustomGateioOut(letcustomGateioOut); setCustomGateioIn(letcustomGateioIn); 
    setCustomHotbitOut(letcustomHotbitOut); setCustomHotbitIn(letcustomHotbitIn); 
    setCustomLiquidOut(letcustomLiquidOut); setCustomLiquidIn(letcustomLiquidIn); 
    setCustomBitmartOut(letcustomBitmartOut); setCustomBitmartIn(letcustomBitmartIn); 
    setCustomTotalOut(letcustomTotalOut); setCustomTotalIn(letcustomTotalIn); 
  }


  return (
    <div className="w-full min-h-[100vh] flex justify-center align-start flex-col flex-nowrap bg-bgprimary dark:bg-darkbgprimary transition-all">
      <div className="w-full min-h-[100vh] bg-backgroundimagepage bg-no-repeat bg-cover">
        {hasTurtle === true ? (
        <div className='h-auto my-5 sm:my-8 md:my-10 bg-bgsecondary dark:bg-darkbgsecondary w-[95%] md:w-[90%] lg:w-4/5 xl:w-3/4 2xl:w-2/3 mx-auto rounded-3xl
          shadow-[0_0px_10px_2px_rgba(15,23,35,0.30)] dark:shadow-[0_0px_10px_2px_rgba(245,245,230,0.2)]'>
          <div className="w-full h-full py-2 flex flex-col mx-auto">
            <div className='px-4 sm:px-6 md:px-8 py-2'>
              {open ? (
                <>
                  <div className='flex flex-row'>
                    <img className='hover:brightness-110 hover:cursor-pointer w-10 h-10' src={turtleuncollapse} onClick={toggle}></img>
                    <h1 className='ml-1 sm:ml-2 text-textprimary dark:text-darktextprimary transition-all text-base sm:text-lg md:text-xl font-bold'>Query the in- and outflow data from and to the exchanges of any timeframe you want!</h1>
                  </div>
                  <div className='text-sm sm:text-base md:text-lg text-textprimary dark:text-darktextprimary transition-all'>
                    <h3>You can query the data from any amount of past blocks, below are a couple quick options</h3>
                    <div className='flex flex-col text-sm'>
                      <div className='flex flex-col md:flex-row'>
                        <p onClick={() => getInflowOutflowDataByTime(12)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>1 minute: <span className='font-bold'>12</span> blocks</p>
                        <p onClick={() => getInflowOutflowDataByTime(60)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>5 minutes: <span className='font-bold'>60</span> blocks</p>
                        <p onClick={() => getInflowOutflowDataByTime(180)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>15 minutes: <span className='font-bold'>180</span> blocks</p>
                      </div>
                      <div className='flex flex-col md:flex-row'>
                        <p onClick={() => getInflowOutflowDataByTime(720)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>1 hour: <span className='font-bold'>720</span> blocks</p>
                        <p onClick={() => getInflowOutflowDataByTime(2880)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>4 hours: <span className='font-bold'>2880</span> blocks</p>
                        <p onClick={() => getInflowOutflowDataByTime(8640)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>12 hours: <span className='font-bold'>8640</span> blocks</p>
                      </div>
                      <div className='flex flex-col md:flex-row'>
                        <p onClick={() => getInflowOutflowDataByTime(17280)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>1 day: <span className='font-bold'>17280</span> blocks</p>
                        <p onClick={() => getInflowOutflowDataByTime(120960)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>1 week: <span className='font-bold'>120960</span> blocks</p>
                        <p onClick={() => getInflowOutflowDataByTime(483840)} className='bg-[rgba(191,219,254,0.2)] px-1 m-[2px] rounded-md hover:cursor-pointer hover:brightness-125 border-[1px] border-[rgba(5,5,12,0.4)] dark:border-[rgba(245,245,230,0.4)] w-[165px]'>1 month: <span className='font-bold'>483840</span> blocks</p>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <h3 className='text-sm sm:text-base md:text-lg text-textprimary dark:text-darktextprimary transition-all'>You can query the data by any amount of past blocks (to a maximum of {currentBlock-(oldestBlock["blockHash"])} blocks). Above are a couple sample timeframes you can use for reference.</h3>
                      <div className=''>
                        <input className='xsm:w-[155px] xs:w-[120px] 2xs:w-[90px] border-[#575757] dark:border-[#b8b8b8] border-[2px] rounded-md px-2' id='blockAmountInput' type="text" />
                        <button id='getDataButton' className='text-textprimary rounded-md ml-1 w-auto px-2 bg-blue-200' onClick={() => getInflowOutflowDataByTime(document.getElementById('blockAmountInput').value)}>Get in- and outflow data</button>
                      </div>
                    </div>
            <div className='mt-2'>
              <p className='text-sm sm:text-base md:text-lg text-textprimary dark:text-darktextprimary transition-all'>
                The following in- and outflow data from and to the exchanges are from the last <span className='font-bold'>{String(secondsToTime2(queryBlocks*5)).slice(0,2)} days, {String(secondsToTime2(queryBlocks*5)).slice(3,5)} hours, {String(secondsToTime2(queryBlocks*5)).slice(6,8)} minutes and {String(secondsToTime2(queryBlocks*5)).slice(9,11)} seconds.</span>
              </p>
            </div>

            <div className='text-textprimary dark:text-darktextprimary transition-all mx-auto flex flex-col h-[173px] lg:h-[88px] border-[#DEE2E6] rounded-md border-[1px]'>

              <div className='lg:flex lg:flex-row'>
                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the EWT staking greenpool" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={stakingpoollogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customStakingIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customStakingOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Kucoin exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={kucoinlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customKucoinIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customKucoinOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>

                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Kraken exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={krakenlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customKrakenIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customKrakenOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Gate.io exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={gateiologo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customGateioIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customGateioOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>
              </div>

              <div className='lg:flex lg:flex-row'>
                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Hotbit exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={hotbitlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customHotbitIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customHotbitOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Liquid exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={liquidlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customLiquidIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customLiquidOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>

                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Bitmart exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={bitmartlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customBitmartIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customBitmartOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="The total in- and outflow to and from the following 6 exchanges combined (Kucoin, Kraken, Gate.io, Hotbit, Liquid and Bitmart)" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={EWTlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {customTotalIn}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {customTotalOut}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>
              </div>

            </div>
                  </div>
                </>
              ) : (<div className='text-lg sm:text-xl flex flex-row'><img className='hover:cursor-pointer hover:brightness-110 w-10 h-10' src={turtlecollapse} onClick={toggle}></img><h1 className='text-base sm:text-lg md:text-xl ml-1 sm:ml-2 text-textprimary dark:text-darktextprimary transition-all font-bold'>Query the in- and outflow data from and to the exchanges of any timeframe you want!</h1></div>)}
            </div>
          </div>
        </div>
        ) : (<></>)}
        <div className='h-auto my-5 sm:my-8 md:my-10 bg-bgsecondary dark:bg-darkbgsecondary w-[95%] md:w-[90%] lg:w-4/5 xl:w-3/4 2xl:w-2/3 mx-auto rounded-3xl
              shadow-[0_0px_10px_2px_rgba(15,23,35,0.30)] dark:shadow-[0_0px_10px_2px_rgba(245,245,230,0.2)]'>
          <div className="w-full h-full py-2 flex flex-col mx-auto">
            <h1 className='text-xl sm:text-2xl md:text-3xl font-bold text-textprimary dark:text-darktextprimary transition-all px-4 sm:px-6 md:px-8 py-2 sm:pt-4'>
              Energy Web Token Big Transactions Tracker
            </h1>
            <h2 className='text-sm sm:text-base md:text-lg text-textprimary dark:text-darktextprimary transition-all px-4 sm:px-6 md:px-8 pb-2'>
              This page will automatically update when a new big transaction (500 EWT or more) happens, so you can just leave this page open, no need to refresh.
              It will only display the latest 100 transactions.
            </h2>
            <p className='text-sm sm:text-base md:text-lg text-textprimary dark:text-darktextprimary transition-all px-4 sm:px-6 md:px-8 pb-3'>
              And it will also play a notification sound when a new big transaction happens (sound only works if you have interacted with the site, so press this button for example).
              <button id='notifs' onClick={notifsOn} className='text-textprimary w-auto text-center bg-[#b3f0b7] hover:brightness-110 border-[#92e696] ml-1 px-[6px] rounded-xl'>Press me!<AiFillBell className='inline ml-[2px] -translate-y-[2px]' /></button>
            </p>

            <div className='px-4 sm:px-6 md:px-8 mb-3'>
              <div className='xsm:text-[0.76rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#e65959]'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={stakingpoollogo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Staking greenpool: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0xA507B00463ce82DF6a105Ab05f810809fAedd538'>
                  0xA507...edd538
                </a>
                <p className='text-textprimary dark:text-darktextprimary transition-all ml-1'>({(stakingPercentage*100).toFixed(2)}% filled)</p>
              </div>
              <p className='xsm:text-[0.76rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[1.6em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#71dbc4] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={kucoinlogo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Kucoin: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0xB0a384fAe14fa7A66Cc7Dd52e079ca2264A05b00'>
                  #1: 0xB0a384fAe14fa7A6...52e079ca2264A05b00
                </a>
              </p>
              <a className='xsm:text-[0.76rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] break-all text-sm text-[#71dbc4] transition-all hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0x89e3Ab767cd56E69A18Cde04C81ABc6520741A62'>
                #2: 0x89e3Ab767cd56E69A18Cde04C81ABc6520741A62
              </a>
              <p className='xsm:text-[0.68rem] xs:text-[0.57rem] 2xs:text-[0.50rem] 3xs:text-[0.51rem] 3xs:leading-[1.6em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#29992e] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={krakenlogo} />
                <span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Kraken:</span>
                <span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Withdraw:</span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0x7136255E5758397506C360625961cc0de6956027'>
                  0x713...56027
                </a>
                <span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>, Deposit:</span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0x0c074090e8216f0600585AADF586F3DE84B2Ee83'>
                  0x0c074...4B2Ee83
                </a>
              </p>
              <p className='xsm:text-[0.76rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#55e650] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={gateiologo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Gate.io: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0x0D0707963952f2fBA59dD06f2b425ace40b492Fe'>
                  0x0D0707963952f2fBA59dD06f2b425ace40b492Fe
                </a>
              </p>
              <p className='xsm:text-[0.76rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#e040c6] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={hotbitlogo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Hotbit: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0xA3C8Ae0772Cf6AF0295131B2E83C79ba3C5A6e58'>
                  0xA3C8Ae0772Cf6AF0295131B2E83C79ba3C5A6e58
                </a>
              </p>
              <p className='xsm:text-[0.78rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#b45e17] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={ewflogo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>EWF Operating Wallet: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0xBff00aE3E5f7F9b1DbDD7E6830eC301b177aC8a9'>
                  0xBff00aE3E5...301b177aC8a9
                </a>
              </p>
              <p className='xsm:text-[0.78rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#b5c527] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={liquidlogo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Liquid: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0x07EA3141809F21e19D2d41c8EC5A1244C247FA0f'>
                  0x07EA3141809F21e19D2d41c8EC5A1244C247FA0f
                </a>
              </p>
              <p className='xsm:text-[0.78rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#90999c] transition-all'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={bitmartlogo} /><span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Bitmart: </span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://explorer.energyweb.org/address/0xC2898A2dF40969F45aC8681155FC354057Ac5f76'>
                  0xC2898A2dF40969F45aC8681155FC354057Ac5f76
                </a>
              </p>
              <p className='xsm:text-[0.78rem] xs:text-[0.65rem] 2xs:text-[0.58rem] 3xs:text-[0.51rem] 3xs:leading-[0.8em] 4xs:text-[0.46rem] flex flex-row break-all text-sm text-[#231fff] transition-all pb-[2px]'>
                <img className='h-[18px] xsm:h-[16px] xs:h-[14px] translate-y-[2px] rounded-md mr-1' src={ewchainiologo} />
                <span className='text-textprimary dark:text-darktextprimary transition-all mr-1'>Any of the other known addresses by:</span>
                <a className='hover:underline flex flex-row' target='_blank' href='https://ewchain.io/wallets'>
                  ewchain.io<AiOutlineLink />
                </a>
              </p>
            </div>

            <div className='mx-4 sm:mx-6 md:mx-8'>
              <p className='text-textprimary dark:text-darktextprimary transition-all'>
                The following in- and outflow data from and to the exchanges are from the last <span className='font-bold'>{String(time100Tx).slice(0, 2)} hours, {String(time100Tx).slice(3, 5)} minutes and {String(time100Tx).slice(6, 8)} seconds.</span>
              </p>
            </div>

            <div className='text-textprimary dark:text-darktextprimary transition-all sm:w-[90%] md:w-[calc(100%-64px)] md:mx-8 sm:mx-auto flex flex-col h-[173px] lg:h-[88px] mx-2 border-[#DEE2E6] rounded-md border-[1px]'>

              <div className='lg:flex lg:flex-row'>
                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the EWT staking greenpool" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={stakingpoollogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inStaking}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outStaking}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Kucoin exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={kucoinlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inKucoin}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outKucoin}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>

                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Kraken exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={krakenlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inKraken}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outKraken}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Gate.io exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={gateiologo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inGateio}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outGateio}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>
              </div>

              <div className='lg:flex lg:flex-row'>
                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Hotbit exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={hotbitlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inHotbit}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outHotbit}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Liquid exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={liquidlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inLiquid}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outLiquid}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>

                <div className='flex flex-row lg:w-1/2'>
                  <div data-for='custom-color' data-tip="In- and outflow to and from the Bitmart exchange" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={bitmartlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inBitmart}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outBitmart}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                  <div data-for='custom-color' data-tip="The total in- and outflow to and from the following 6 exchanges combined (Kucoin, Kraken, Gate.io, Hotbit, Liquid and Bitmart)" className='py-[2px] text-[0.80rem] xs:text-[0.78rem] 2xs:text-[0.73rem] leading-[0.9rem] w-1/2 border-[#DEE2E6] rounded-md border-[1px] m-1 flex items-center'>
                    <img className='h-[18px] xsm:h-[16px] xs:h-[14px] rounded-md ml-1' src={EWTlogo} alt="" />
                    <div className='flex flex-col ml-1'>
                      <div>Inflow: {inTotalExchanges}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                      <div>Outflow: {outTotalExchanges}<img className='inline h-[11px] -translate-y-[2px] ml-[2px]' src={EWTlogo} />
                      </div>
                    </div>
                  </div>
                  <ReactTooltip id='custom-color' className='custom-color' backgroundColor='#3D414Cff' textColor='#FFFFFF' place="top" effect="solid" />
                </div>
              </div>

            </div>

            <div className='mt-2 flex flex-row w-full mb-2 sm:mb-3 md:mb-4'>
              <div className='xs:text-[0.77rem] 2xs:text-[0.70rem] 3xs:text-[0.66rem] text-sm sm:text-base font-bold flex justify-center items-center w-1/6 dark:brightness-[0.85] rounded-tl-xl rounded-bl-xl ml-4 sm:ml-6 md:ml-8 h-[36px] bg-[#a9ddff]'>500+</div>
              <div className='xs:text-[0.77rem] 2xs:text-[0.70rem] 3xs:text-[0.66rem] text-sm sm:text-base font-bold flex justify-center items-center w-1/6 dark:brightness-[0.85] h-[36px] bg-[#acffa9]'>1000+</div>
              <div className='xs:text-[0.77rem] 2xs:text-[0.70rem] 3xs:text-[0.66rem] text-sm sm:text-base font-bold flex justify-center items-center w-1/6 dark:brightness-[0.85] h-[36px] bg-[#fffea9]'>2500+</div>
              <div className='xs:text-[0.77rem] 2xs:text-[0.70rem] 3xs:text-[0.66rem] text-sm sm:text-base font-bold flex justify-center items-center w-1/6 dark:brightness-[0.85] h-[36px] bg-[#ffd093]'>5000+</div>
              <div className='xs:text-[0.77rem] 2xs:text-[0.70rem] 3xs:text-[0.66rem] text-sm sm:text-base font-bold flex justify-center items-center w-1/6 dark:brightness-[0.85] h-[36px] bg-[#ffa9ad]'>10000+</div>
              <div className='xs:text-[0.77rem] 2xs:text-[0.70rem] 3xs:text-[0.66rem] text-sm sm:text-base font-bold flex justify-center items-center w-1/6 dark:brightness-[0.85] rounded-tr-xl rounded-br-xl mr-4 sm:mr-6 md:mr-8 h-[36px] bg-[#d5a9ff]'>25000+</div>
            </div>
            <div className='flex flex-row'>
              <p style={colorStyle} className='font-bold text-sm sm:text-base md:text-lg text-textprimary dark:text-darktextprimary transition-all pl-4 sm:pl-6 md:pl-8 pb-1 sm:pb-2'>
                Current block: {currentblock}
              </p>
              <MoonLoader className='inline ml-2' size={20} color="#007BFF" />
            </div>
            <audio id="myAudio" autoPlay src={newTxAudio}></audio>
            {txlist !== [] && txlist.length !== 0 ? (
              <div className='px-2 sm:px-3 md:px-4 lg:px-6 py-2'>
                {txlist.slice(0, 100).map((tx) => (
                  <div className='loadedtransactions flex flex-col my-4 md:my-3 md:flex-row border-[#DEE2E6] rounded-md border-[1px]'>
                    <div className='py-1 md:rounded-tl-md md:rounded-bl-md bg-[#E5F2FF] dark:bg-[#0c0533] border-[#007BFF] px-2 sm:px-3 md:px-6 lg:px-12 xl:px-16 flex flex-col border-[1px] border-l-[4px]'>
                      <div className='text-sm sm:text-base my-auto'>
                        <p className='text-center font-bold text-[#007BFF]'>Transaction</p>
                        <p className='text-center text-[#3B86FF]'>Success</p>
                        {tx[0]["txvalue"] >= 500 && tx[0]["txvalue"] < 1000 ? (<img className='h-[36px] mx-auto rounded-3xl bg-[#a9ddff] dark:brightness-[0.85]' src={electrobuzz} />) : (<></>)}
                        {tx[0]["txvalue"] >= 1000 && tx[0]["txvalue"] < 2500 ? (<img className='h-[36px] mx-auto rounded-3xl bg-[#acffa9] dark:brightness-[0.85]' src={jolteon} />) : (<></>)}
                        {tx[0]["txvalue"] >= 2500 && tx[0]["txvalue"] < 5000 ? (<img className='h-[36px] mx-auto rounded-3xl bg-[#fffea9] dark:brightness-[0.85]' src={zapdos} />) : (<></>)}
                        {tx[0]["txvalue"] >= 5000 && tx[0]["txvalue"] < 10000 ? (<img className='h-[36px] mx-auto rounded-3xl bg-[#ffd093] dark:brightness-[0.85]' src={pichu} />) : (<></>)}
                        {tx[0]["txvalue"] >= 10000 && tx[0]["txvalue"] < 25000 ? (<img className='h-[36px] mx-auto rounded-3xl bg-[#ffa9ad] dark:brightness-[0.85]' src={pickachu} />) : (<></>)}
                        {tx[0]["txvalue"] >= 25000 ? (<img className='h-[36px] mx-auto rounded-3xl bg-[#d5a9ff]' src={raichu} />) : (<></>)}
                      </div>
                    </div>
                    <div className='text-sm sm:text-base py-2 flex flex-col m-2 md:mx-3 lg:mx-4 justify-center'>
                      <a className='xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#6644A5] dark:brightness-[2] hover:underline' target='_blank' href={'https://explorer.energyweb.org/tx/' + `${tx[0]["txhash"]}`}>{String((tx[0]["txhash"]).slice(0, 20)) + '...' + String((tx[0]["txhash"]).slice(-20))}</a>
                      <div className='flex flex-row'>
                        {(() => {
                          if (tx[0]["from"] === "0xA507B00463ce82DF6a105Ab05f810809fAedd538") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#e65959] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={stakingpoollogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0xB0a384fAe14fa7A66Cc7Dd52e079ca2264A05b00" || tx[0]["from"] === "0x89e3Ab767cd56E69A18Cde04C81ABc6520741A62") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#71dbc4] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={kucoinlogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0x7136255E5758397506C360625961cc0de6956027") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#29992e] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={krakenlogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0x0D0707963952f2fBA59dD06f2b425ace40b492Fe") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#55e650] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={gateiologo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0xA3C8Ae0772Cf6AF0295131B2E83C79ba3C5A6e58") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#e040c6] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={hotbitlogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0xBff00aE3E5f7F9b1DbDD7E6830eC301b177aC8a9") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#b45e17] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={ewflogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0x07EA3141809F21e19D2d41c8EC5A1244C247FA0f") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#b5c527] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={liquidlogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (tx[0]["from"] === "0xC2898A2dF40969F45aC8681155FC354057Ac5f76" || tx[0]["from"] === "0xaD8199326fb304ae8f36F28C1e95947Cb64c87b7") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#90999c] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={bitmartlogo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else if (
                            tx[0]["from"] === "0x120470000000000000000000000000000000000a" ||
                            tx[0]["from"] === "0x1204700000000000000000000000000000000003" ||
                            tx[0]["from"] === "0x69aF0912dd44dce2B2373dB4021788CbAd84Ff35" ||
                            tx[0]["from"] === "0xb561618a3Ea959a5E363643B267C4CB8fe4B1DF7" ||
                            tx[0]["from"] === "0x410aCA87630bC74E32113f4d19C8853362103255" ||
                            tx[0]["from"] === "0x7D1AE9AEef3ed42f3DB4E6b29C82379F6eb6095b" ||
                            tx[0]["from"] === "0x699a66B6EC95db389C685F07Eee08E09ADfCD541" ||
                            tx[0]["from"] === "0x2af2E09A69f3C79E6d357cfa67028C7fb10DEe7c" ||
                            tx[0]["from"] === "0x90eA07EC516f5957b5dd6251FB1B65C7bC7eC4b4" ||
                            tx[0]["from"] === "0x6523b3cFd89D20924117b1D659c6d82417d7e36a" ||
                            tx[0]["from"] === "0xEA097e845bE2Fb4f37aF292EEdad6bfDA7dE5047" ||
                            tx[0]["from"] === "0x0966962952fdFfB57C814BaD395B4bd001CF12b9" ||
                            tx[0]["from"] === "0x1D1A606c4a8459CB531080313191c1Ea4e6cF94A" ||
                            tx[0]["from"] === "0x87ef0070ea79da428d5b3428dCF13088536Fe771" ||
                            tx[0]["from"] === "0xFD679097fE0F914642af9857e5799332Fe2Efa29" ||
                            tx[0]["from"] === "0x06920Bb91F7027176Cf373996D39B539ba436D87" ||
                            tx[0]["from"] === "0x3f11B4ad17FdE4695CAd64E109AE92a679d87Bfc" ||
                            tx[0]["from"] === "0x47428fc08e56388372e7C81Ad4A1140d932d1096" ||
                            tx[0]["from"] === "0x7030892dbF9c2048E796296dDA597F145754a185" ||
                            tx[0]["from"] === "0x8c994AdA51d35B8519424368807fb99C10336686" ||
                            tx[0]["from"] === "0xDe6B493D368316b9078454e37DCE4968482Dfbe9" ||
                            tx[0]["from"] === "0xD6216fC19DB775Df9774a6E33526131dA7D19a2c" ||
                            tx[0]["from"] === "0x3Bd4D48D022ACA4C3FC1Fe673CF4741D5888bcc7" ||
                            tx[0]["from"] === "0x8df330b8966ebE69Be996653e50252c6D44a527a" ||
                            tx[0]["from"] === "0xa0827857Fa91a9b4D720268B61B2dC96baa019FA" ||
                            tx[0]["from"] === "0x9430a215FFca453252137908CAACD504c224e66D" ||
                            tx[0]["from"] === "0xc77B908353E648C3C08A9071E9129EA9fcdA4e23" ||
                            tx[0]["from"] === "0x87449aa1412e34F0450cc5aa795200e05B050Ae1" ||
                            tx[0]["from"] === "0xf8Eb4F3b9C179A5E0f76Bc1A7A8b15a5B74996d9" ||
                            tx[0]["from"] === "0xb1DD5415dFa3E82E63AF394e48F0d4D8E686425d" ||
                            tx[0]["from"] === "0x9c562c804Aedf1B506b61E45C83A789Bf207B08b" ||
                            tx[0]["from"] === "0x177e147e5552903629CcE2e42361Edd899ec7978" ||
                            tx[0]["from"] === "0xc0C32DcF53C2Ddd97087eE0042729705b2E6cc25" ||
                            tx[0]["from"] === "0x874008d366995B5aEF76a5126D8A5F1aE934ab80" ||
                            tx[0]["from"] === "0xb920432447e50f21EbdeAA6e5431a8E91DD991d8" ||
                            tx[0]["from"] === "0x9e88dd25B6236E625709CA2830DEe8d0C70bdA7D" ||
                            tx[0]["from"] === "0x5831d2FBbF925c5131c75a2197e73C416C966A59" ||
                            tx[0]["from"] === "0x44BAc1e2A2f6b44f71C5bfA8cfdcbeb73dac859b" ||
                            tx[0]["from"] === "0x63Be1C775237f4c02f94396a7a7837d6C9B14424" ||
                            tx[0]["from"] === "0xa93c0d81BA7Cd8a0c41016F09d02aA385586b23A" ||
                            tx[0]["from"] === "0xAD6F905f533970F591DF630670007E0c5315668d" ||
                            tx[0]["from"] === "0x319736d0E5E921DF081E50cB36C7B4BA3642a651" ||
                            tx[0]["from"] === "0xA11214FdBCe59e256B859eF0be2481ACfd98A314" ||
                            tx[0]["from"] === "0xD65b4C25A4CE1E024fF13425Df1E0E574a1a0e9B" ||
                            tx[0]["from"] === "0x045df4ea79B1F17e451B9EB5E153Db8AE95fe79c" ||
                            tx[0]["from"] === "0xf37D30f8325771450C2ee1Eb5B38BF4cC6E4338B" ||
                            tx[0]["from"] === "0x8aFd93BF5Bd9446c7a2070BA0B151f3f1CF1fF30" ||
                            tx[0]["from"] === "0xc56B672Afd3FAE873bf8A40bbE80f51075b187CA" ||
                            tx[0]["from"] === "0x904e3eaB954eB80dAbd5435db4bcc7413863C28A" ||
                            tx[0]["from"] === "0xD28a4659dF172152Cf3812DeC96edDAa99a38c42" ||
                            tx[0]["from"] === "0xb5DA81B0c1C808EF6d761dacfba725bfE7BDF0b8" ||
                            tx[0]["from"] === "0x46d8BcDB927E20A56B33BBD0da75E32b540af9F9" ||
                            tx[0]["from"] === "0x86a5A44CFf58638784c2028e7181CEDe57933321" ||
                            tx[0]["from"] === "0xdad22897407d4f5E93E5d8b49B19ABeBA4b4BD89" ||
                            tx[0]["from"] === "0x10B598DAd5ddd32b85A1B8F4365615D6Bdc42a78" ||
                            tx[0]["from"] === "0xFB7720716B3B2FA8940E3F46B7C53843718BF813" ||
                            tx[0]["from"] === "0x9cD949e2ce9F1408Dee663318F5281c5ED94e534" ||
                            tx[0]["from"] === "0xf02BA87b74453A54D5Ebb5A048cbacB90046cDD9" ||
                            tx[0]["from"] === "0x25B02276431d29E481878140A0db68A8A1350212" ||
                            tx[0]["from"] === "0x46495E58Bc75Cd44A8eC6eE6200A6035eF0332a0" ||
                            tx[0]["from"] === "0x54809eA74BECdD734d2C4329729835ab39BB23F3" ||
                            tx[0]["from"] === "0x936fB33641b1e7dFe277d40C0c38621C8dCC4357" ||
                            tx[0]["from"] === "0x292DaDa7A8CF9603e8dDFBD5bF2E580818A5ab76" ||
                            tx[0]["from"] === "0x6C87141Be1105C37B8B27dAa25975c1575C22fD5" ||
                            tx[0]["from"] === "0x4c8FC2cAd90bEE5332E887d3c9184424E76b11E0" ||
                            tx[0]["from"] === "0xb1DD5415dFa3E82E63AF394e48F0d4D8E686425d" ||
                            tx[0]["from"] === "0x73455eAc3304742695527D2AD455c8eE890c4473" ||
                            tx[0]["from"] === "0x3975B79cA5f14757D0613B4AfE3694EC147A7167" ||
                            tx[0]["from"] === "0x4813E2b291c373bF7a8590B695B0A77f43D765d6" ||
                            tx[0]["from"] === "0xBc11295936Aa79d594139de1B2e12629414F3BDB" ||
                            tx[0]["from"] === "0xABCae6fe36b82DFaEb8F4A21eab4aC67454E2a3D" ||
                            tx[0]["from"] === "0x06920Bb91F7027176Cf373996D39B539ba436D87" ||
                            tx[0]["from"] === "0xC32E395d5DC1dDf2eE8390f53DB8b5Ee3fdc1043" ||
                            tx[0]["from"] === "0x41fcf95a80cA2B6E95B3AF33029FC83DE309823b" ||
                            tx[0]["from"] === "0x815E18cD4238568E70edeFeBDa45696b07134Eb6" ||
                            tx[0]["from"] === "0xB045E809005E4a5fa405659c39fb9a6af6Bebbe2" ||
                            tx[0]["from"] === "0xe595048f2C467a3123C4BCDE597962467Fb13DA6" ||
                            tx[0]["from"] === "0xD7DEccdc1cF5Dfd34dF8A03135162D7F294FEDB0" ||
                            tx[0]["from"] === "0x9F0F002b674312fb729E86bce5bB588Fd7a5d672" ||
                            tx[0]["from"] === "0xbC04309D494B97a562Be67A4A86B28Bc0c583C10" ||
                            tx[0]["from"] === "0xcfE7964b0b6412b013Dc019bDF3AfEf58be56593" ||
                            tx[0]["from"] === "0xfD7A30D3C2bD017A458610274C275059D308b2E7" ||
                            tx[0]["from"] === "0x871ba4266793aD11dA537D4857dE7Ad49EaB662b" ||
                            tx[0]["from"] === "0xb61C11B6E42d459EFAEe8995c44DB08507e468E1" ||
                            tx[0]["from"] === "0x656E5569BeF7781bf0Db199D32027766053501ff" ||
                            tx[0]["from"] === "0x1153818a2EB49F0a71B27313C32814fc02E4Db50" ||
                            tx[0]["from"] === "0x6Dd10e41A7a84FE23ab35feFa2F46C9895F87a2D" ||
                            tx[0]["from"] === "0x15696134ebeed360dc90DC97dDD00bd07e1C11e9" ||
                            tx[0]["from"] === "0x349ebc5a6e853df121c84E999081e5992928e64F" ||
                            tx[0]["from"] === "0x3A9d83766c03c465851a38DaA364ef7DEccd1ECE" ||
                            tx[0]["from"] === "0x3C9f867D9b3a595987E198786FA9aB722E5C2F9b" ||
                            tx[0]["from"] === "0x4d0aA1c3459BF41e3Ad4E4F40Bbf029Cb5723d83" ||
                            tx[0]["from"] === "0x6a0A5dA2a48ea87C2A906C53b3373642c29a4b6C" ||
                            tx[0]["from"] === "0x96A5eb172EfdF262Ed6beaaF0E20C6Af71831Fc9" ||
                            tx[0]["from"] === "0xBe4888C5b021E5F16cd254DE2D4EAF17625685C4" ||
                            tx[0]["from"] === "0xc1d441a2aD43Af7b4A3d8E3200d2cEB3A973099D" ||
                            tx[0]["from"] === "0xE23c7cB60189BB2FD60625d2C2747B1e68f10776" ||
                            tx[0]["from"] === "0x002D4606B65c033769968bCdc63881b90B0853f5" ||
                            tx[0]["from"] === "0x6D516767E4068FC331BDb331fba7578BDb07a68c" ||
                            tx[0]["from"] === "0x1f0c30B1aA4C468B5Beb02BaC8DF8F27617a2296" ||
                            tx[0]["from"] === "0xD44FB8De580D34F44789408cC9335c9A9cE0cE4d" ||
                            tx[0]["from"] === "0x25Ae7b45D8646580dfcAE403D29164729eB8642f" ||
                            tx[0]["from"] === "0x664f991cDb2ffe6b6A568edE65b0208DBCCE6f72" ||
                            tx[0]["from"] === "0xA720A8EE90f5013cAe9BF7BCac1d153E42815454" ||
                            tx[0]["from"] === "0xDb6cc57168c07b83a00f1f8871538446068824fC" ||
                            tx[0]["from"] === "0xe6E8A111C89B05337049dE9349C7C4880a396EF1" ||
                            tx[0]["from"] === "0xFfCf98C62c1BAd480aB6846717B173A72e2dD090" ||
                            tx[0]["from"] === "0xb999004B49C6b907D4278067DA5c85195DCD7Fc7" ||
                            tx[0]["from"] === "0xf4e31018A926F64CB780cB9f5f027377bCfb26fC" ||
                            tx[0]["from"] === "0x5fBb9c482034D287c5B3848Fc2F9272abdD5Bfa2" ||
                            tx[0]["from"] === "0x880E8b0ecE0171EDd0247f8d13D348d77A6b9b29" ||
                            tx[0]["from"] === "0xA69DCa0814EAadC89B6dBE94c5e2110497690f6C" ||
                            tx[0]["from"] === "0xC58A20e290E858542D8e8bb07b600aeb9195fe30" ||
                            tx[0]["from"] === "0x404Bb9c13364522133B363d5C4Adb7a88056B19d" ||
                            tx[0]["from"] === "0x00C2F65230815D30EaA1A4d057bCF0B72FE3cc4e" ||
                            tx[0]["from"] === "0x03CccDC799d4DC37d56E3f9DBa7f9c210fa1086f" ||
                            tx[0]["from"] === "0x047D955877a55Fbdac768573a9259f29B103a066" ||
                            tx[0]["from"] === "0x0ad7Ba4aF33B485e6F2505c417554631a3e5643f" ||
                            tx[0]["from"] === "0x3AbAA3f24428d6028F5A7FC5b18ce9D04cCec229" ||
                            tx[0]["from"] === "0x428aB4B019EE3a9B9863B2B4Bf1885CE6dff9a73" ||
                            tx[0]["from"] === "0x48Ee57fAf61c0b963113e7921e6173629e6bc443" ||
                            tx[0]["from"] === "0x57f33EfaD76D4B783cf42c9e6Cb08f4425dfe96e" ||
                            tx[0]["from"] === "0x7ed62CF71D519d3Bf293ef90829508F92F4CCCcb" ||
                            tx[0]["from"] === "0x9196e46D664CEDa55CB45a2CC5ab5Bd1b7e614E2" ||
                            tx[0]["from"] === "0x943c85B13f24083Ec73815f7Ba763B7c42Ae0288" ||
                            tx[0]["from"] === "0x9467B762550673F08B14423F8562048d5E369422" ||
                            tx[0]["from"] === "0xB476Ee7D610DAe7B23B671EBC7Bd6112E9772969" ||
                            tx[0]["from"] === "0xdAcD80D8e1d4f117515CAA477eE7599cDfc76619" ||
                            tx[0]["from"] === "0xFFd9b871dF6e93803C0877E98fC1722B39c00d78" ||
                            tx[0]["from"] === "0x006e371C454a2d081f3966c180Ba2C6165d87DE6" ||
                            tx[0]["from"] === "0x06FdB93aa64F33A8fb40a36c462a3f7A074D632C" ||
                            tx[0]["from"] === "0x0dd959deB4C458cc2aC379898Bf2c99f7A8F399B" ||
                            tx[0]["from"] === "0x2540dED041b6FEdC0Ff6F0CF26b891Ec97C95400" ||
                            tx[0]["from"] === "0x3885D15573E45228Dd54Cd4FDe9BfAc64d702ED4" ||
                            tx[0]["from"] === "0x3F12af735238C6E2FA45eFB5b2F3FAE82Df4c922" ||
                            tx[0]["from"] === "0x5b3fb4e1d6040615F3e681bEc4C80b5d7C958071" ||
                            tx[0]["from"] === "0x6cf32Cc52E220C023C2d92B1D62310F46a6E2a13" ||
                            tx[0]["from"] === "0x949423dB1BFeE1ddEc99c9D24a12a6eA27cb3489" ||
                            tx[0]["from"] === "0xB080454F190E76eB8e719560fA8CAE50c71bceA9" ||
                            tx[0]["from"] === "0xb5B6D8885fbF28f843Cc7886DE242B811d695205" ||
                            tx[0]["from"] === "0xd33D4F83e85c92E0B53ffe4FC0E18b0E3632C097" ||
                            tx[0]["from"] === "0xEbbDDf28bF3224791B0510a2AB8813f182fe4e2b" ||
                            tx[0]["from"] === "0xf8E6ecb4B0f17576525749bDF85524652cbf002E" ||
                            tx[0]["from"] === "0x887f2B16847248bc757b69F3c695F24Ff344dAF2" ||
                            tx[0]["from"] === "0x1204700000000000000000000000000000000004" ||
                            tx[0]["from"] === "0x82c79F48F6c58fE624E5e499c314572987Fe9E18" ||
                            tx[0]["from"] === "0x2ce42c2B3aCff7eddcfd32DCB0703F1870b0eBe1" ||
                            tx[0]["from"] === "0xc7d0695c53D17dac89846BfBaFc897293BEf4A20"
                          ) {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#231fff] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}><img className='h-[18px] translate-y-[2px] rounded-md mr-1' src={ewchainiologo} />{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                          else {
                            return (
                              <a className='xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#6644A5] dark:brightness-[2] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["from"]}`}>{String((tx[0]["from"]).slice(0, 7)) + '...' + String((tx[0]["from"]).slice(-7))}</a>
                            )
                          }
                        })()}
                        <BsArrowRight className='text-[#6644A5] dark:brightness-[2] mx-1 my-auto' />
                        {(() => {
                          if (tx[0]["to"] === "0xA507B00463ce82DF6a105Ab05f810809fAedd538") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.72rem] 3xs:text-[0.68rem] text-[#e65959] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={stakingpoollogo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0xB0a384fAe14fa7A66Cc7Dd52e079ca2264A05b00" || tx[0]["to"] === "0x89e3Ab767cd56E69A18Cde04C81ABc6520741A62") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.72rem] 3xs:text-[0.68rem] text-[#71dbc4] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={kucoinlogo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0x0c074090e8216f0600585AADF586F3DE84B2Ee83") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.72rem] 3xs:text-[0.68rem] text-[#29992e] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={krakenlogo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0x0D0707963952f2fBA59dD06f2b425ace40b492Fe") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#55e650] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={gateiologo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0xA3C8Ae0772Cf6AF0295131B2E83C79ba3C5A6e58") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#e040c6] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={hotbitlogo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0xBff00aE3E5f7F9b1DbDD7E6830eC301b177aC8a9") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#b45e17] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={ewflogo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0x07EA3141809F21e19D2d41c8EC5A1244C247FA0f") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#b5c527] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={liquidlogo} /></a>
                            )
                          }
                          else if (tx[0]["to"] === "0xC2898A2dF40969F45aC8681155FC354057Ac5f76" || tx[0]["from"] === "0xaD8199326fb304ae8f36F28C1e95947Cb64c87b7") {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#90999c] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={bitmartlogo} /></a>
                            )
                          }
                          else if (
                            tx[0]["to"] === "0x120470000000000000000000000000000000000a" ||
                            tx[0]["to"] === "0x1204700000000000000000000000000000000003" ||
                            tx[0]["to"] === "0x69aF0912dd44dce2B2373dB4021788CbAd84Ff35" ||
                            tx[0]["to"] === "0xb561618a3Ea959a5E363643B267C4CB8fe4B1DF7" ||
                            tx[0]["to"] === "0x410aCA87630bC74E32113f4d19C8853362103255" ||
                            tx[0]["to"] === "0x7D1AE9AEef3ed42f3DB4E6b29C82379F6eb6095b" ||
                            tx[0]["to"] === "0x699a66B6EC95db389C685F07Eee08E09ADfCD541" ||
                            tx[0]["to"] === "0x2af2E09A69f3C79E6d357cfa67028C7fb10DEe7c" ||
                            tx[0]["to"] === "0x90eA07EC516f5957b5dd6251FB1B65C7bC7eC4b4" ||
                            tx[0]["to"] === "0x6523b3cFd89D20924117b1D659c6d82417d7e36a" ||
                            tx[0]["to"] === "0xEA097e845bE2Fb4f37aF292EEdad6bfDA7dE5047" ||
                            tx[0]["to"] === "0x0966962952fdFfB57C814BaD395B4bd001CF12b9" ||
                            tx[0]["to"] === "0x1D1A606c4a8459CB531080313191c1Ea4e6cF94A" ||
                            tx[0]["to"] === "0x87ef0070ea79da428d5b3428dCF13088536Fe771" ||
                            tx[0]["to"] === "0xFD679097fE0F914642af9857e5799332Fe2Efa29" ||
                            tx[0]["to"] === "0x06920Bb91F7027176Cf373996D39B539ba436D87" ||
                            tx[0]["to"] === "0x3f11B4ad17FdE4695CAd64E109AE92a679d87Bfc" ||
                            tx[0]["to"] === "0x47428fc08e56388372e7C81Ad4A1140d932d1096" ||
                            tx[0]["to"] === "0x7030892dbF9c2048E796296dDA597F145754a185" ||
                            tx[0]["to"] === "0x8c994AdA51d35B8519424368807fb99C10336686" ||
                            tx[0]["to"] === "0xDe6B493D368316b9078454e37DCE4968482Dfbe9" ||
                            tx[0]["to"] === "0xD6216fC19DB775Df9774a6E33526131dA7D19a2c" ||
                            tx[0]["to"] === "0x3Bd4D48D022ACA4C3FC1Fe673CF4741D5888bcc7" ||
                            tx[0]["to"] === "0x8df330b8966ebE69Be996653e50252c6D44a527a" ||
                            tx[0]["to"] === "0xa0827857Fa91a9b4D720268B61B2dC96baa019FA" ||
                            tx[0]["to"] === "0x9430a215FFca453252137908CAACD504c224e66D" ||
                            tx[0]["to"] === "0xc77B908353E648C3C08A9071E9129EA9fcdA4e23" ||
                            tx[0]["to"] === "0x87449aa1412e34F0450cc5aa795200e05B050Ae1" ||
                            tx[0]["to"] === "0xf8Eb4F3b9C179A5E0f76Bc1A7A8b15a5B74996d9" ||
                            tx[0]["to"] === "0xb1DD5415dFa3E82E63AF394e48F0d4D8E686425d" ||
                            tx[0]["to"] === "0x9c562c804Aedf1B506b61E45C83A789Bf207B08b" ||
                            tx[0]["to"] === "0x177e147e5552903629CcE2e42361Edd899ec7978" ||
                            tx[0]["to"] === "0xc0C32DcF53C2Ddd97087eE0042729705b2E6cc25" ||
                            tx[0]["to"] === "0x874008d366995B5aEF76a5126D8A5F1aE934ab80" ||
                            tx[0]["to"] === "0xb920432447e50f21EbdeAA6e5431a8E91DD991d8" ||
                            tx[0]["to"] === "0x9e88dd25B6236E625709CA2830DEe8d0C70bdA7D" ||
                            tx[0]["to"] === "0x5831d2FBbF925c5131c75a2197e73C416C966A59" ||
                            tx[0]["to"] === "0x44BAc1e2A2f6b44f71C5bfA8cfdcbeb73dac859b" ||
                            tx[0]["to"] === "0x63Be1C775237f4c02f94396a7a7837d6C9B14424" ||
                            tx[0]["to"] === "0xa93c0d81BA7Cd8a0c41016F09d02aA385586b23A" ||
                            tx[0]["to"] === "0xAD6F905f533970F591DF630670007E0c5315668d" ||
                            tx[0]["to"] === "0x319736d0E5E921DF081E50cB36C7B4BA3642a651" ||
                            tx[0]["to"] === "0xA11214FdBCe59e256B859eF0be2481ACfd98A314" ||
                            tx[0]["to"] === "0xD65b4C25A4CE1E024fF13425Df1E0E574a1a0e9B" ||
                            tx[0]["to"] === "0x045df4ea79B1F17e451B9EB5E153Db8AE95fe79c" ||
                            tx[0]["to"] === "0xf37D30f8325771450C2ee1Eb5B38BF4cC6E4338B" ||
                            tx[0]["to"] === "0x8aFd93BF5Bd9446c7a2070BA0B151f3f1CF1fF30" ||
                            tx[0]["to"] === "0xc56B672Afd3FAE873bf8A40bbE80f51075b187CA" ||
                            tx[0]["to"] === "0x904e3eaB954eB80dAbd5435db4bcc7413863C28A" ||
                            tx[0]["to"] === "0xD28a4659dF172152Cf3812DeC96edDAa99a38c42" ||
                            tx[0]["to"] === "0xb5DA81B0c1C808EF6d761dacfba725bfE7BDF0b8" ||
                            tx[0]["to"] === "0x46d8BcDB927E20A56B33BBD0da75E32b540af9F9" ||
                            tx[0]["to"] === "0x86a5A44CFf58638784c2028e7181CEDe57933321" ||
                            tx[0]["to"] === "0xdad22897407d4f5E93E5d8b49B19ABeBA4b4BD89" ||
                            tx[0]["to"] === "0x10B598DAd5ddd32b85A1B8F4365615D6Bdc42a78" ||
                            tx[0]["to"] === "0xFB7720716B3B2FA8940E3F46B7C53843718BF813" ||
                            tx[0]["to"] === "0x9cD949e2ce9F1408Dee663318F5281c5ED94e534" ||
                            tx[0]["to"] === "0xf02BA87b74453A54D5Ebb5A048cbacB90046cDD9" ||
                            tx[0]["to"] === "0x25B02276431d29E481878140A0db68A8A1350212" ||
                            tx[0]["to"] === "0x46495E58Bc75Cd44A8eC6eE6200A6035eF0332a0" ||
                            tx[0]["to"] === "0x54809eA74BECdD734d2C4329729835ab39BB23F3" ||
                            tx[0]["to"] === "0x936fB33641b1e7dFe277d40C0c38621C8dCC4357" ||
                            tx[0]["to"] === "0x292DaDa7A8CF9603e8dDFBD5bF2E580818A5ab76" ||
                            tx[0]["to"] === "0x6C87141Be1105C37B8B27dAa25975c1575C22fD5" ||
                            tx[0]["to"] === "0x4c8FC2cAd90bEE5332E887d3c9184424E76b11E0" ||
                            tx[0]["to"] === "0xb1DD5415dFa3E82E63AF394e48F0d4D8E686425d" ||
                            tx[0]["to"] === "0x73455eAc3304742695527D2AD455c8eE890c4473" ||
                            tx[0]["to"] === "0x3975B79cA5f14757D0613B4AfE3694EC147A7167" ||
                            tx[0]["to"] === "0x4813E2b291c373bF7a8590B695B0A77f43D765d6" ||
                            tx[0]["to"] === "0xBc11295936Aa79d594139de1B2e12629414F3BDB" ||
                            tx[0]["to"] === "0xABCae6fe36b82DFaEb8F4A21eab4aC67454E2a3D" ||
                            tx[0]["to"] === "0x06920Bb91F7027176Cf373996D39B539ba436D87" ||
                            tx[0]["to"] === "0xC32E395d5DC1dDf2eE8390f53DB8b5Ee3fdc1043" ||
                            tx[0]["to"] === "0x41fcf95a80cA2B6E95B3AF33029FC83DE309823b" ||
                            tx[0]["to"] === "0x815E18cD4238568E70edeFeBDa45696b07134Eb6" ||
                            tx[0]["to"] === "0xB045E809005E4a5fa405659c39fb9a6af6Bebbe2" ||
                            tx[0]["to"] === "0xe595048f2C467a3123C4BCDE597962467Fb13DA6" ||
                            tx[0]["to"] === "0xD7DEccdc1cF5Dfd34dF8A03135162D7F294FEDB0" ||
                            tx[0]["to"] === "0x9F0F002b674312fb729E86bce5bB588Fd7a5d672" ||
                            tx[0]["to"] === "0xbC04309D494B97a562Be67A4A86B28Bc0c583C10" ||
                            tx[0]["to"] === "0xcfE7964b0b6412b013Dc019bDF3AfEf58be56593" ||
                            tx[0]["to"] === "0xfD7A30D3C2bD017A458610274C275059D308b2E7" ||
                            tx[0]["to"] === "0x871ba4266793aD11dA537D4857dE7Ad49EaB662b" ||
                            tx[0]["to"] === "0xb61C11B6E42d459EFAEe8995c44DB08507e468E1" ||
                            tx[0]["to"] === "0x656E5569BeF7781bf0Db199D32027766053501ff" ||
                            tx[0]["to"] === "0x1153818a2EB49F0a71B27313C32814fc02E4Db50" ||
                            tx[0]["to"] === "0x6Dd10e41A7a84FE23ab35feFa2F46C9895F87a2D" ||
                            tx[0]["to"] === "0x15696134ebeed360dc90DC97dDD00bd07e1C11e9" ||
                            tx[0]["to"] === "0x349ebc5a6e853df121c84E999081e5992928e64F" ||
                            tx[0]["to"] === "0x3A9d83766c03c465851a38DaA364ef7DEccd1ECE" ||
                            tx[0]["to"] === "0x3C9f867D9b3a595987E198786FA9aB722E5C2F9b" ||
                            tx[0]["to"] === "0x4d0aA1c3459BF41e3Ad4E4F40Bbf029Cb5723d83" ||
                            tx[0]["to"] === "0x6a0A5dA2a48ea87C2A906C53b3373642c29a4b6C" ||
                            tx[0]["to"] === "0x96A5eb172EfdF262Ed6beaaF0E20C6Af71831Fc9" ||
                            tx[0]["to"] === "0xBe4888C5b021E5F16cd254DE2D4EAF17625685C4" ||
                            tx[0]["to"] === "0xc1d441a2aD43Af7b4A3d8E3200d2cEB3A973099D" ||
                            tx[0]["to"] === "0xE23c7cB60189BB2FD60625d2C2747B1e68f10776" ||
                            tx[0]["to"] === "0x002D4606B65c033769968bCdc63881b90B0853f5" ||
                            tx[0]["to"] === "0x6D516767E4068FC331BDb331fba7578BDb07a68c" ||
                            tx[0]["to"] === "0x1f0c30B1aA4C468B5Beb02BaC8DF8F27617a2296" ||
                            tx[0]["to"] === "0xD44FB8De580D34F44789408cC9335c9A9cE0cE4d" ||
                            tx[0]["to"] === "0x25Ae7b45D8646580dfcAE403D29164729eB8642f" ||
                            tx[0]["to"] === "0x664f991cDb2ffe6b6A568edE65b0208DBCCE6f72" ||
                            tx[0]["to"] === "0xA720A8EE90f5013cAe9BF7BCac1d153E42815454" ||
                            tx[0]["to"] === "0xDb6cc57168c07b83a00f1f8871538446068824fC" ||
                            tx[0]["to"] === "0xe6E8A111C89B05337049dE9349C7C4880a396EF1" ||
                            tx[0]["to"] === "0xFfCf98C62c1BAd480aB6846717B173A72e2dD090" ||
                            tx[0]["to"] === "0xb999004B49C6b907D4278067DA5c85195DCD7Fc7" ||
                            tx[0]["to"] === "0xf4e31018A926F64CB780cB9f5f027377bCfb26fC" ||
                            tx[0]["to"] === "0x5fBb9c482034D287c5B3848Fc2F9272abdD5Bfa2" ||
                            tx[0]["to"] === "0x880E8b0ecE0171EDd0247f8d13D348d77A6b9b29" ||
                            tx[0]["to"] === "0xA69DCa0814EAadC89B6dBE94c5e2110497690f6C" ||
                            tx[0]["to"] === "0xC58A20e290E858542D8e8bb07b600aeb9195fe30" ||
                            tx[0]["to"] === "0x404Bb9c13364522133B363d5C4Adb7a88056B19d" ||
                            tx[0]["to"] === "0x00C2F65230815D30EaA1A4d057bCF0B72FE3cc4e" ||
                            tx[0]["to"] === "0x03CccDC799d4DC37d56E3f9DBa7f9c210fa1086f" ||
                            tx[0]["to"] === "0x047D955877a55Fbdac768573a9259f29B103a066" ||
                            tx[0]["to"] === "0x0ad7Ba4aF33B485e6F2505c417554631a3e5643f" ||
                            tx[0]["to"] === "0x3AbAA3f24428d6028F5A7FC5b18ce9D04cCec229" ||
                            tx[0]["to"] === "0x428aB4B019EE3a9B9863B2B4Bf1885CE6dff9a73" ||
                            tx[0]["to"] === "0x48Ee57fAf61c0b963113e7921e6173629e6bc443" ||
                            tx[0]["to"] === "0x57f33EfaD76D4B783cf42c9e6Cb08f4425dfe96e" ||
                            tx[0]["to"] === "0x7ed62CF71D519d3Bf293ef90829508F92F4CCCcb" ||
                            tx[0]["to"] === "0x9196e46D664CEDa55CB45a2CC5ab5Bd1b7e614E2" ||
                            tx[0]["to"] === "0x943c85B13f24083Ec73815f7Ba763B7c42Ae0288" ||
                            tx[0]["to"] === "0x9467B762550673F08B14423F8562048d5E369422" ||
                            tx[0]["to"] === "0xB476Ee7D610DAe7B23B671EBC7Bd6112E9772969" ||
                            tx[0]["to"] === "0xdAcD80D8e1d4f117515CAA477eE7599cDfc76619" ||
                            tx[0]["to"] === "0xFFd9b871dF6e93803C0877E98fC1722B39c00d78" ||
                            tx[0]["to"] === "0x006e371C454a2d081f3966c180Ba2C6165d87DE6" ||
                            tx[0]["to"] === "0x06FdB93aa64F33A8fb40a36c462a3f7A074D632C" ||
                            tx[0]["to"] === "0x0dd959deB4C458cc2aC379898Bf2c99f7A8F399B" ||
                            tx[0]["to"] === "0x2540dED041b6FEdC0Ff6F0CF26b891Ec97C95400" ||
                            tx[0]["to"] === "0x3885D15573E45228Dd54Cd4FDe9BfAc64d702ED4" ||
                            tx[0]["to"] === "0x3F12af735238C6E2FA45eFB5b2F3FAE82Df4c922" ||
                            tx[0]["to"] === "0x5b3fb4e1d6040615F3e681bEc4C80b5d7C958071" ||
                            tx[0]["to"] === "0x6cf32Cc52E220C023C2d92B1D62310F46a6E2a13" ||
                            tx[0]["to"] === "0x949423dB1BFeE1ddEc99c9D24a12a6eA27cb3489" ||
                            tx[0]["to"] === "0xB080454F190E76eB8e719560fA8CAE50c71bceA9" ||
                            tx[0]["to"] === "0xb5B6D8885fbF28f843Cc7886DE242B811d695205" ||
                            tx[0]["to"] === "0xd33D4F83e85c92E0B53ffe4FC0E18b0E3632C097" ||
                            tx[0]["to"] === "0xEbbDDf28bF3224791B0510a2AB8813f182fe4e2b" ||
                            tx[0]["to"] === "0xf8E6ecb4B0f17576525749bDF85524652cbf002E" ||
                            tx[0]["to"] === "0x887f2B16847248bc757b69F3c695F24Ff344dAF2" ||
                            tx[0]["to"] === "0x1204700000000000000000000000000000000004" ||
                            tx[0]["to"] === "0x82c79F48F6c58fE624E5e499c314572987Fe9E18" ||
                            tx[0]["to"] === "0x2ce42c2B3aCff7eddcfd32DCB0703F1870b0eBe1" ||
                            tx[0]["to"] === "0xc7d0695c53D17dac89846BfBaFc897293BEf4A20"
                          ) {
                            return (
                              <a className='flex flex-row xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#231fff] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}<img className='h-[18px] translate-y-[2px] rounded-md ml-1' src={ewchainiologo} /></a>
                            )
                          }
                          else {
                            return (
                              <a className='xs:text-[0.81rem] 2xs:text-[0.71rem] 3xs:text-[0.68rem] text-[#6644A5] dark:brightness-[2] hover:underline' target='_blank' href={'https://explorer.energyweb.org/address/' + `${tx[0]["to"]}`}>{String((tx[0]["to"]).slice(0, 7)) + '...' + String((tx[0]["to"]).slice(-7))}</a>
                            )
                          }
                        })()}
                      </div>
                      <div className='text-[#5A5D5A] dark:text-darktextprimary transition-all'>EWT transferred: {Math.round((tx[0]["txvalue"]) * 1000000) / 1000000} <img className='inline-block mb-[2px] h-[20px]' src={EWTlogo} alt="" /></div>
                    </div>
                    <div className='flex flex-col mr-0 ml-auto'>
                      <div className='mb-0 mt-auto flex ml-auto mr-2 md:mr-3 lg:mr-4 items-center'>
                        <a className='text-[#6644A5] dark:brightness-[2] text-[0.78rem] sm:text-sm'>{secondsToTime((parseInt(currentblock) - parseInt(tx[0]["blockHash"])) * 5)}</a>
                      </div>
                      <div className='flex ml-auto mr-2 md:mr-3 lg:mr-4 items-center mb-1 sm:mb-2'>
                        <a className='text-[#6644A5] dark:brightness-[2] text-[0.78rem] sm:text-sm hover:underline' target='_blank' href={'https://explorer.energyweb.org/block/' + `${tx[0]["blockHash"]}`}>Block #{tx[0]["blockHash"]}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (<></>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;